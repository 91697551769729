<template>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
      <div v-for="user of users" :key="user" :class="{'col-span-3': expendedUserId === user.id}">
        <StreamPlayer :userId="user.id" @expanded="expand"></StreamPlayer>
      </div>
  </div>
</template>

<script>

import StreamPlayer from '@/views/apps/proctor/StreamPlayer'

export default {
  components: {
    StreamPlayer
  },
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      expendedUserId: null
    }
  },
  computed: {
    
  },
  methods: {
    expand(userId) {
      this.expendedUserId = userId

      // Order the players and put the expanded on the top
      if (!this.isEmpty(this.expendedUserId)) {
        const index = this.users.findIndex(user => {
          return user.id === this.expendedUserId
        })
        const element = this.users.splice(index, 1)
        this.users = [element[0], ...this.users]
      }

      this.$root.$emit('collapse_all', userId)
    }
  },
  mounted() {
    

  },
  beforeMount() {

    this.users = [
      {
        id: 2
      },
      {
        id: 3
      },
      {
        id: 4
      },
      {
        id: 5
      },
      {
        id: 6
      },
      {
        id: 7
      },
      {
        id: 8
      },
      {
        id: 9
      },
      {
        id: 10
      },
      {
        id: 11
      },
    ]
  },
  beforeDestroy() {
    
  }
}
</script>

<style>


</style>
