var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-1 md:grid-cols-3 gap-2" },
    _vm._l(_vm.users, function (user) {
      return _c(
        "div",
        { key: user, class: { "col-span-3": _vm.expendedUserId === user.id } },
        [
          _c("StreamPlayer", {
            attrs: { userId: user.id },
            on: { expanded: _vm.expand },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }